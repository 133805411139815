<template>
  <div class="app">
    <div class="socials">
      <a class="socials__anchor socials__anchor--pdf" @click.prevent="printPage">
        <pdf class="socials__icon"></pdf>
      </a>
      <a class="socials__anchor socials__anchor--github" href="//github.com/adamsheridan" target="_blank">
        <github class="socials__icon"></github>
      </a>
      <a class="socials__anchor socials__anchor--linkedin" href="//www.linkedin.com/in/html6/" target="_blank">
        <linkedin class="socials__icon"></linkedin>
      </a>
    </div>
    <header class="header" role="banner">
      <div class="masthead">
        <logo class="masthead__logo logo"></logo>
      </div>
      <p class="intro" v-html="data.intro"></p>
    </header>
    <main role="main">
      <ul class="skills">
        <li class="skills__skill" v-for="(skill, index) in data.skills" :key="`skill-${index}`">{{ skill }}</li>
      </ul>
      <ul class="history">
        <li class="contract" v-for="(contract, index) in data.history" :key="`contract-${index}`">
          <p class="contract__title">{{ contract.title }}</p>
          <p class="contract__date">{{ contract.date }}</p>
          <p class="contract__description">{{ contract.description }}</p>
        </li>
      </ul>
    </main>
    <footer class="footer" role="contentinfo">
      <h3 class="footer__title">Contact</h3>
      If you're an agency or a startup and have an interesting project which needs some help please email me at <a href="mailto:adam@asheridan.co.uk">adam@asheridan.co.uk</a>.
    </footer>
  </div>
</template>

<script>
import Logo from '../../components/Logo';
import Github from '../../components/icons/Github';
import Linkedin from '../../components/icons/Linkedin';
import Pdf from '../../components/icons/Pdf';

// JSON CMS LOL
import content from '../../content.json';

export default {
  name: 'App',
  components: {
    Logo,
    Github,
    Linkedin,
    Pdf,
  },
  data() {
    return {
      data: content,
    }
  },
  methods: {
    printPage() {
      window.print();
    },
  },
}
</script>

<style lang="scss">

$black: #000;
$white: #fff;
$lightgray: #f0f0f0;
$midgray: #5c5c5c;
$darkgray: #323232;

.app {
  background: $lightgray;
  border: 5px solid $white;
  color: $darkgray;
  font-family: 'Nunito', serif;
  line-height: 1.7;
  padding: 25px;
  position: relative;
  -webkit-font-smoothing: antialiased;

  @media screen and (min-width: 524px) {
    border-size: 20px;
    padding: 40px;
  }

  @media screen and (min-width: 1024px) {
    padding: 80px;
  }

  @media screen and (min-width: 1100px) {
    padding: 80px 37% 80px 80px;
  }

  .logo {
    color: $midgray;
    height: 100px;
    width: 100px;
  }
}

strong {
  font-weight: 600;
}

a {
  color: $darkgray;
  font-weight: 500;
}

.socials {
  display: none;
  position: absolute;
  right: 25px;

  @media screen and (min-width: 768px) {
    right: 80px;
  }

  @media screen and (min-width: 1024px) {
    display: block;
  }

  &__anchor {
    cursor: pointer;
    margin-right: 18px;

    &:last-child {
      margin-right: 0;
    }

    &--pdf {
      display: none;

      @media screen and (min-width: 800px) {
        display: inline-block;
      }
    }
  }

  &__icon {
    color: $darkgray;
    height: 32px;
    width: 32px;
  }
}

.masthead {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.intro {
  font-size: 22px;
  margin-top: 80px;

  @media screen and (min-width: 800px) {
    font-size: 28px;
  }
}

.history {
  padding: 0;
}

.contract {
  display: flex;
  flex-direction: column;
  list-style: none;
  margin-bottom: 40px;

  @media screen and (min-width: 800px) {
    margin-bottom: 80px;
  }

  &__title {
    font-size: 28px;
    margin-bottom: 10px;
  }

  &__date {
    font-size: 18px;
    margin: 0;
  }

  &__description {
    font-size: 16px;

    @media screen and (min-width: 800px) {
      font-size: 18px;
    }
  }
}

.skills {
  margin: 80px 0;
  padding: 0;

  @media screen and (min-width: 800px) {
    margin-top: 140px;
  }

  &__skill {
    background: $midgray;
    border-radius: 5px;
    color: $white;
    display: inline-block;
    font-size: 12px;
    list-style: none;
    margin: 0 10px 10px 0;
    padding: 5px 10px;

    @media screen and (min-width: 800px) {
      font-size: 16px;
    }
  }
}

.footer {
  font-size: 16px;

  @media screen and (min-width: 800px) {
    font-size: 18px;
  }

  &__title {
    font-size: 28px;
    font-weight: normal;
    margin-bottom: 10px;
  }
}

@media print {

  @page {
    margin: 0;
    size: A4 portrait;
  }

  p {
    orphans: 3;
    widows: 3;
  }

  .app {
    background-color: transparent;
    padding: 80px;
  }

  .intro {
    font-size: 24px;
    margin-bottom: 100px;
  }

  .socials {
    display: none;
  }

  .skills {
    margin: 30px 0;

    &__skill {
      background: transparent;
      color: $darkgray;
      padding: 0;
    }
  }

  .history {
    page-break-before: always;
  }

  .contract {
    margin: 0 0 40px 0;
    page-break-inside: avoid;

    &__title {
      font-size: 18px;
    }

    &__date,
    &__description {
      font-size: 12px;
    }

    &:nth-child(1n) {
      margin-top: 80px;
    }

    &:nth-child(3n) {
      page-break-after: always;
    }
  }
}

</style>
