<template>
  <svg version="1.1" x="0px" y="0px" viewBox="0 0 62 62" style="enable-background:new 0 0 62 62;">
    <g fill="currentColor">
      <path d="M40.5,5.8C39.3,4.6,37.7,4,36,4H21c-5.8,0-10.5,4.7-10.5,10.5v37C10.5,57.3,15.2,62,21,62h24c5.8,0,10.5-4.7,10.5-10.5   V22.7c0-1.8-0.7-3.5-2-4.7L40.5,5.8z M50.5,51.5c0,3-2.5,5.5-5.5,5.5H21c-3,0-5.5-2.5-5.5-5.5v-37c0-3,2.5-5.5,5.5-5.5h14.1v8   c0,3.6,2.9,6.5,6.5,6.5h8.9V51.5z"/>
      <path d="M21.9,39.9c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3C23.3,40.5,22.7,39.9,21.9,39.9z"/>
      <path d="M28.8,35.4c-0.8,0-1.5,0.3-2,1v-0.8h-2.2v9.3h2.2v-3.4c0.6,0.7,1.2,1,2,1c1.7,0,3-1.3,3-3.6C31.7,36.7,30.5,35.4,28.8,35.4   z M28.1,40.6c-0.5,0-1-0.3-1.3-0.6V38c0.3-0.4,0.8-0.6,1.3-0.6c0.8,0,1.5,0.6,1.5,1.6C29.5,40,28.9,40.6,28.1,40.6z"/>
      <path d="M37.4,36.4c-0.5-0.7-1.3-1-2-1c-1.7,0-3,1.3-3,3.5c0,2.3,1.3,3.6,3,3.6c0.8,0,1.4-0.3,2-1v0.8h2.1V33h-2.1V36.4z M37.4,40   c-0.3,0.4-0.8,0.6-1.3,0.6c-0.9,0-1.5-0.6-1.5-1.7s0.6-1.6,1.5-1.6c0.5,0,1,0.2,1.3,0.6V40z"/>
      <path d="M41.6,35.6h-1.1v1.9h1.1v4.9h2.2v-4.9h1.4v-1.9h-1.4v-0.2c0-0.6,0.3-0.8,0.7-0.8c0.3,0,0.5,0.1,0.7,0.2l0.8-1.3   c-0.4-0.4-1.2-0.6-1.9-0.6c-1.4,0-2.4,1-2.4,2.6V35.6z"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Pdf',
  // Kiran Shastry
}
</script>
